import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Close from '../images/close.png'

const EmailForm = styled.form`
  position: fixed;
  background-color: white;
  bottom: 40px;
  right: 40px;
  display: grid;
  border: 1px solid black;
  padding: 15px 10px;
  z-index: 101;

  label {
    font-size: 12px;
    margin: 0 0 20px 0;
    font-style: italic;
    letter-spacing: 2px;
  }

  @media (max-width: 1000px) {
    position: relative;
    bottom: auto;
    right: auto;
    z-index: 1;
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid black;
  width: max-content;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  top: -10px;
  right: -10px;

  img {
    width: 10px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  width: 450px;
  appearance: none;
  border-radius: 0;

  input {
    padding: 5px 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 10px;
    border: 1px solid black;
    appearance: none;
    border-radius: 0;
  }

  input[type='email'] {
    border-right: none;
  }

  input[type='submit'] {
    border-left: none;
    color: white;
    background-color: #0000ff;
    cursor: pointer;
  }

  input:focus {
    outline: 0;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`

const Alert = styled.p`
  font-size: 10px;
  margin: 10px 0 0 0;
`

const Email = () => {
  const [result, setResult] = useState()
  const [email, setEmail] = useState('')
  const [alert, setAlert] = useState('')
  const [showForm, setShowForm] = useState(true)

  useEffect(() => {
    const data = sessionStorage.getItem('showForm')
    if (data === 'false') {
      setShowForm(false)
    }
  })

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    setResult(result)
    setAlert('Your email has been added to the mailing list.')
  }

  const closeForm = () => {
    sessionStorage.setItem('showForm', 'false')
    setShowForm(false)
  }

  return (
    showForm && (
      <EmailForm onSubmit={handleSubmit}>
        <CloseWrapper onClick={closeForm}>
          <img src={Close} alt="close icon" />
        </CloseWrapper>
        <label>Subscribe for exclusive content</label>
        <InputWrapper>
          <input
            type="email"
            placeholder="Email:"
            value={email}
            onChange={handleEmail}
          />
          <input type="submit" value="Enter" />
        </InputWrapper>
        <Alert>{alert}</Alert>
      </EmailForm>
    )
  )
}

export default Email
