import React, { useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { navigate } from "gatsby";

import { useAddItemToCart, useStore } from "../context/StoreContext";
import { prepareVariantsWithOptions } from "./utilities";

import ImageSlider from "../components/imageSlider";
import EmailForm from "../components/EmailForm";

import PantsSize from "../images/pants-size.jpg";

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 150px;
  box-sizing: border-box;
  text-transform: uppercase;
  position: relative;

  h2 {
    font-size: 16px;
    font-weight: 400;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1000px) {
    padding: 150px 20px;
  }

  @media (max-width: 700px) {
    padding: 100px 20px;
  }
`;

const Wrapper = styled.div`
  margin: 50px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "image details" "other-images other-images";
  align-items: start;
  grid-gap: 40px;

  @media (max-width: 1000px) {
    margin: 0 0 0 0;
    grid-template-areas: "image" "details" "other-images";
    grid-template-columns: 1fr;
  }
`;

const ImageWrapper = styled.div`
  grid-area: image;
`;

const OtherImageWrapper = styled.div`
  display: grid;
  grid-gap: 10px 0;
  grid-area: other-images;
  margin-top: 40px;
`;

const DetailsWrapper = styled.div`
  align-self: center;
  grid-area: details;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 20px;

  h1 {
    font-size: 18px;
    font-weight: 400;
    border: 1px solid black;
    font-stretch: 151%;
    padding: 10px;
    height: max-content;
  }
`;

const DescriptionWrapper = styled.div`
  p {
    font-weight: 300;
    font-size: 14px;
    margin: 15px 0 0 20px;
    line-height: 1.3;
  }
`;

const AddToCart = styled.input`
  cursor: pointer;
  background-color: #0000ff;
  border: 1px solid black;
  color: white;
  padding: 10px 15px;
  text-transform: uppercase;
  width: max-content;
  justify-self: center;
  appearance: none;
  outline: none;
  border-radius: 0;
`;

const SoldOut = styled.input`
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 10px 15px;
  text-transform: uppercase;
  width: max-content;
  justify-self: center;
  appearance: none;
  outline: none;
  border-radius: 0;
  text-align: center;
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 18px;
  justify-self: center;
`;

const MaterialWrapper = styled.div`
  margin: 0 0 15px 0;
`;

const Material = styled.div`
  margin: 15px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  > h3 {
    grid-column: 1 / -1;
  }

  > p {
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0 0 20px;
  }
`;

const SizeWrapper = styled.div`
  display: grid;
  grid-gap: 0 10px;
  justify-self: center;
  grid-auto-flow: column;
`;

const Size = styled.label`
  cursor: ${(props) => `${props.cursor}`};
  text-decoration: ${(props) => `${props.strikethrough}`};

  input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    transition: background-color 0.1s ease, color 0.1s ease;
  }

  span {
    display: block;
    padding: 7px 14px;
    border: 1px solid black;
    font-size: 14px;
    background-color: ${(props) => `${props.defaultColor}`};
    color: ${(props) => `${props.defaultTextColor}`};
  }

  &:hover span {
    background-color: ${(props) => `${props.color}`};
    color: ${(props) => `${props.textColor}`};
  }
`;

const StyledImg = styled(Img)`
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 0;
    margin-right: 40px;
    scroll-snap-align: center;
  }
`;

const SizingImage = styled.img`
  width: 100%;
  margin-top: 40px;
`;

const ProductTemplate = ({ pageContext }) => {
  const { product, cms } = pageContext;
  const addItemToCart = useAddItemToCart();
  const store = useStore();
  const client = store.client;

  const priceRange = product && product.priceRange.maxVariantPrice.amount;
  const price = Math.round(priceRange);

  let sizes = product.options.find(
    (option) => option.name.toLowerCase() === "size"
  );

  if (sizes) {
    sizes = sizes.values;
  }

  const variants = useMemo(() => prepareVariantsWithOptions(product.variants), [
    product.variants,
  ]);

  console.log(variants);
  console.log(variants[0]);

  const isFirstAvailable = () => {
    for (let i = 0; i < product.variants.length; i++) {
      if (product.variants[i].availableForSale === true) {
        return i;
      }
    }
  };

  const [productAvailable, setProductAvailable] = useState(
    product.availableForSale
  );
  const [firstAvailIndex, setFirstAvailIndex] = useState(isFirstAvailable());
  const [variant, setVariant] = useState(variants[0]);
  const [sizesAvail, setSizesAvail] = useState(
    Array(sizes && sizes.values.length).fill(true)
  );
  const [size, setSize] = useState(
    product.variants[firstAvailIndex]
      ? product.variants[firstAvailIndex].selectedOptions[0].value
      : variant.size
  );
  const [trackIndex, setTrackIndex] = useState(firstAvailIndex);

  useEffect(() => {
    const newVariant = variants.find((variant) => {
      return variant.size === size;
    });

    if (newVariant && variant.shopifyId !== newVariant.shopifyId) {
      setVariant(newVariant);
    }
  }, [size, variants, variant.shopifyId]);

  const checkAvailability = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        let sizes_availability = [];

        if (fetchedProduct.variants.length > 0) {
          sizes_availability = fetchedProduct.variants.map((variant) => {
            return variant.available;
          });
        }

        if (fetchedProduct.variants.length > 1) {
          let first_true_index = sizes_availability.findIndex(
            (el) => el === true
          );

          setFirstAvailIndex(first_true_index);
          setTrackIndex(first_true_index);
          setSize(product.variants[first_true_index].selectedOptions[0].value);
          setSizesAvail(sizes_availability);
        }
      });
    },
    [client.product, product.variants]
  );

  const checkAvailabilitySingle = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        setProductAvailable(fetchedProduct.availableForSale);
      });
    },
    [client.product]
  );

  useEffect(() => {
    checkAvailability(product.shopifyId);
    checkAvailabilitySingle(product.shopifyId);
  }, [variants, product, checkAvailability, checkAvailabilitySingle]);

  function handleAddToCart() {
    addItemToCart(variant.shopifyId, 1);
    navigate("/cart");
  }

  let restOfImgs = product.images.slice(1, product.images.length);

  if (
    cms &&
    cms.node.frontmatter.images &&
    cms.node.frontmatter.images.length !== 0
  ) {
    restOfImgs = product.images;
  }

  const handleSizeClick = (sizeVal, i) => {
    setSize(sizeVal);
    setTrackIndex(i);
  };

  useEffect(() => {
    document.getElementById(`${product.title}-description`).innerHTML =
      product.descriptionHtml;
  }, []);

  const renderAddToCart = () => {
    if (productAvailable) {
      return (
        <AddToCart
          type="button"
          value="Add to Cart"
          onClick={handleAddToCart}
        ></AddToCart>
      );
    } else {
      return <SoldOut value="Sold Out" disabled="disabled" />;
    }
  };

  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          {cms &&
          cms.node.frontmatter.images &&
          cms.node.frontmatter.images.length !== 0 ? (
            <ImageSlider images={cms.node.frontmatter.images} />
          ) : (
            <StyledImg
              fluid={product.images[0].localFile.childImageSharp.fluid}
            />
          )}
        </ImageWrapper>
        <DetailsWrapper>
          <h1>{product.title}</h1>
          <DescriptionWrapper>
            <h2>Description</h2>
            <div id={`${product.title}-description`}></div>
          </DescriptionWrapper>
          <MaterialWrapper>
            {cms ? (
              <>
                <h2>Material</h2>
                {cms.node.frontmatter.materialinfo.map((data, i) => {
                  return (
                    <Material key={`data-${i}`}>
                      <h3>{data.materialname}</h3>
                      <p>{data.field1}</p>
                      <p>{data.field2}</p>
                      <p>{data.field3}</p>
                    </Material>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </MaterialWrapper>
          <Price>{`$${price}`}</Price>
          <SizeWrapper>
            {sizes &&
              sizes.map((size, i) => {
                return (
                  <Size
                    key={`${size}-${i}`}
                    color={sizesAvail[i] ? "#0000ff" : "white"}
                    textColor={sizesAvail[i] ? "white" : "black"}
                    cursor={sizesAvail[i] ? "pointer" : "default"}
                    strikethrough={sizesAvail[i] ? "none" : "line-through"}
                    defaultColor={i === trackIndex ? "#0000ff" : "white"}
                    defaultTextColor={i === trackIndex ? "white" : "black"}
                  >
                    <input
                      disabled={sizesAvail[i] ? "" : "disabled"}
                      key={`radio-${size}-${i}`}
                      onClick={(event) =>
                        handleSizeClick(event.target.value, i)
                      }
                      type="radio"
                      name="size"
                      value={size}
                      defaultChecked={i === firstAvailIndex}
                    />
                    <span>{size}</span>
                  </Size>
                );
              })}
          </SizeWrapper>
          {renderAddToCart()}
          {/* {sizes && <SizingImage src={PantsSize} />} */}
          {/* <ShippingInfo>Shipping Info</ShippingInfo> */}
        </DetailsWrapper>
        <OtherImageWrapper>
          {restOfImgs.map((image, i) => (
            <StyledImg
              key={`other-image-${i}`}
              fluid={image.localFile.childImageSharp.fluid}
            />
          ))}
        </OtherImageWrapper>
        <EmailForm />
      </Wrapper>
    </Container>
  );
};
export default ProductTemplate;
