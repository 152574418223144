import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const SliderContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 0;
    margin-right: 40px;
    scroll-snap-align: center;
  }
`;

const SliderImageContainer = styled.div`
  width: 100%;
  height: calc((100vw - 300px - 40px) / 2);
  position: relative;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    height: calc(100vw - 100px);
    width: calc(100vw - 100px);
    margin: 0 auto 20px auto;
  }

  @media (min-width: 1600px) {
    height: calc(1260px / 2);
  }
`;

const SliderImage = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: ${(props) => props.z};
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SliderInput = styled.input`
  width: 50%;
  appearance: none;
  height: 2px;
  border-radius: 5px;
  background: black;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;

  @media (max-width: 500px) {
    width: 70%;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
`;

const ImageSlider = ({ images }) => {
  const numImages = images.length;

  const [range, setRange] = useState(
    (Math.floor((numImages - 1) / 2) / (numImages - 1)) * 100
  );

  const handleRange = (e) => {
    setRange(e.target.value);
  };

  return (
    <SliderContainer>
      <SliderImageContainer>
        {images.map((img, i) => {
          if (i !== numImages - 1) {
            return (
              <SliderImage
                fluid={img.childImageSharp.fluid}
                z={i + 1}
                show={
                  range < (100 / numImages) * (i + 1) &&
                  range >= (100 / numImages) * i
                    ? true
                    : false
                }
              />
            );
          } else {
            return (
              <SliderImage
                fluid={img.childImageSharp.fluid}
                z={i + 1}
                show={
                  range <= (100 / numImages) * (i + 1) &&
                  range >= (100 / numImages) * i
                    ? true
                    : false
                }
              />
            );
          }
        })}
      </SliderImageContainer>
      <InputContainer>
        <SliderInput
          type="range"
          min="0"
          max="100"
          defaultValue={range}
          onChange={handleRange}
        />
      </InputContainer>
    </SliderContainer>
  );
};

export default ImageSlider;
